export const ICARS_TABS = {
    NEEDS_ASSESSMENT: {
        eventKey: 'needsAssessment',
        title: 'Needs and Assessment',
    },
    CLIENT_PROFILE: {
        eventKey: 'clientProfile',
        title: 'Client Profile',
    },
    INFORMATION_ORIENTATION: {
        eventKey: 'informationOrientation',
        title: 'Information and Orientation',
    },
    COMMUNITY_CONNECTIONS: {
        eventKey: 'communityConnections',
        title: 'Community Connections',
    },
    EMPLOYMENT: {
        eventKey: 'employment',
        title: 'Employment',
    },
};

export const NARS = {
    PRELIMINARY: "preliminary",
    ASSESSMENT: "assessment",
    IRCC_PROGRAM_NEEDS: "ircc_program_needs",
    IRCC_SUPPORT_SERVICES_NEEDED: "ircc_support_services_needed",
    NON_IRCC_PROGRAM_NEEDS: "non_ircc_program_needs",
    NON_IRCC_SUPPORT_SERVICES_NEEDED: "non_ircc_support_services_needed",
    COMPLETE: "complete",
};

export const INFO = {
    GENERAL_INFORMATION: "general_info",
    SERVICE_INFORMATION: "service_info",
    ORIENTATION_SERVICE: "orientation_service",
    ORIENTATION_TOPIC: "orientation_topic",
    SKILLS_AND_APTITUDE: "skills_and_aptitude",
    SUPPORT_SERVICES: "support_services",
    COMPLETE: "complete",
};

export const NARS_SUPPORT_SERVICES_REQUIRED = [
    {
        fieldName: "childminding_required_ind",
        label: "Child Care"
    },
    {
        fieldName: "transportation_required_ind",
        label: "Transportation"
    },
    {
        fieldName: "support_disability_required_ind",
        label: "Provisions for Disabilities"
    },
    {
        fieldName: "translation_required_ind",
        label: "Translation"
    },
    {
        fieldName: "interpretation_required_ind",
        label: "Interpretation"
    },
    {
        fieldName: "counselling_required_ind",
        label: "Short Term Counselling"
    },
]

export const SUPPORT_SERVICE_RECIEVED_OPTION = [
    {
        fieldName: "childminding_ind",
        label: "Child Care"
    },
    {
        fieldName: "transportation_ind",
        label: "Transportation"
    },
    {
        fieldName: "support_disability_ind",
        label: "Provisions for Disabilities"
    },
    {
        fieldName: "translation_ind",
        label: "Translation"
    },
    {
        fieldName: "interpretation_ind",
        label: "Interpretation"
    },
    {
        fieldName: "counselling_ind",
        label: "Short Term Counselling"
    },
]

export const NARS_SERVICE_DELIVERY_OPTIONS = [
    {
        fieldName: "service_delivery_format_in_person_ind",
        label: "In person"
    },
    {
        fieldName: "service_delivery_format_telephone_ind",
        label: "Telephone call/text/email"
    },
    {
        fieldName: "service_delivery_format_online_staff_ind",
        label: "Staff-led Online Service"
    },
    {
        fieldName: "service_delivery_format_online_self_ind",
        label: "Self-directed Online Service"
    },
    {
        fieldName: "service_delivery_format_correspondence_ind",
        label: "Self-directed via Correspondence"
    },
]

export const TRAINING_RECIEVED_OPTIONS = [
    {
        fieldName: "training_received_computer_ind",
        label: "Computer skills"
    },
    {
        fieldName: "training_received_document_ind",
        label: "Document Use"
    },
    {
        fieldName: "training_received_interpersonal_ind",
        label: "Interpersonal Skills and Workplace Culture"
    },
    {
        fieldName: "training_received_leadership_ind",
        label: "Leadership Training"
    },
    {
        fieldName: "training_received_numeracy_ind",
        label: "Numeracy"
    }
]

export const CMCN_TRAINING_RECIEVED_OPTIONS = [
    {
        fieldName: "training_received_computer_ind",
        label: "Computer skills"
    },
    {
        fieldName: "training_received_document_ind",
        label: "Document Use"
    },
    {
        fieldName: "training_received_interpersonal_ind",
        label: "Interpersonal Skills and Workplace Culture"
    },
    {
        fieldName: "training_received_life_skills_ind",
        label: "Life Skills"
    },
    {
        fieldName: "training_received_leadership_ind",
        label: "Leadership Training"
    },
    {
        fieldName: "training_received_numeracy_ind",
        label: "Numeracy"
    }
]

export const IAO_ESSENTIAL_SKILL_OPTIONS = [
    {
        fieldName: "essential_skill_life_ind",
        label: "Life Skills"
    },
    {
        fieldName: "essential_skill_responsabilities_ind",
        label: "Rights and Responsibilities of Citizenship (based on discover Canada)"
    }
]

export const IAO_ORIENTATION_TOPICS_COVERED = [
    {
        label: "Overview of Canada",
        fieldName: "topic_overview_given_ind",
        referralFieldName: "topic_overview_referral_ind"
    },
    {
        label: "Sources of Information",
        fieldName: "topic_information_given_ind",
        referralFieldName: "topic_information_referral_ind"
    },
    {
        label: "Rights and Freedoms",
        fieldName: "topic_rights_given_ind",
        referralFieldName: "topic_rights_referral_ind"
    },
    {
        label: "Canadian Law and Justice",
        fieldName: "topic_law_given_ind",
        referralFieldName: "topic_law_referral_ind"
    },
    {
        label: "Important Documents",
        fieldName: "topic_documents_given_ind",
        referralFieldName: "topic_documents_referral_ind"
    },
    {
        label: "Improving English or French",
        fieldName: "topic_language_given_ind",
        referralFieldName: "topic_language_referral_ind"
    },
    {
        label: "Employment and Income",
        fieldName: "topic_income_given_ind",
        referralFieldName: "topic_income_referral_ind"
    },
    {
        label: "Education",
        fieldName: "topic_education_given_ind",
        referralFieldName: "topic_education_referral_ind"
    },
    {
        label: "Housing",
        fieldName: "topic_housing_given_ind",
        referralFieldName: "topic_housing_referral_ind"
    },
    {
        label: "Health",
        fieldName: "topic_health_given_ind",
        referralFieldName: "topic_health_referral_ind"
    },
    {
        label: "Money and Finances",
        fieldName: "topic_money_given_ind",
        referralFieldName: "topic_money_referral_ind"
    },
    {
        label: "Transportation",
        fieldName: "topic_transportation_given_ind",
        referralFieldName: "topic_transportation_referral_ind"
    },
    {
        label: "Communications and Media",
        fieldName: "topic_media_given_ind",
        referralFieldName: "topic_media_referral_ind"
    },
    {
        label: "Community Engagement",
        fieldName: "topic_community_given_ind",
        referralFieldName: "topic_community_referral_ind"
    },
    {
        label: "Becoming a Canadian Citizen",
        fieldName: "topic_citizenship_given_ind",
        referralFieldName: "topic_citizenship_referral_ind"
    },
    {
        label: "Interpersonal Conflict",
        fieldName: "topic_conflict_given_ind",
        referralFieldName: "topic_conflict_referral_ind"
    },
]

export const TARGET_GROUP_OPTIONS = [
    {
        fieldName: "target_group_children_ind",
        label: "Children (0-14 yrs)"
    },
    {
        fieldName: "target_group_youth_ind",
        label: "Youth (15-30 yrs)"
    },
    {
        fieldName: "target_group_senior_ind",
        label: "Seniors"
    },
    {
        fieldName: "target_group_gender_ind",
        label: "Gender-specific"
    },
    {
        fieldName: "target_group_refugee_ind",
        label: "Refugees"
    },
    {
        fieldName: "target_group_ethnic_ind",
        label: "Ethnic/cultural/linguistic group"
    },
    {
        fieldName: "target_group_hearing_ind",
        label: "Deaf or Hard of Hearing"
    },
    {
        fieldName: "target_group_visual_ind",
        label: "Blind or Partially Sighted"
    },
    {
        fieldName: "target_group_LGBTQ_ind",
        label: "Lesbian, Gay, Bisexual, Transgender, Queer (LGBTQ)"
    },
    {
        fieldName: "target_group_families_parents_ind",
        label: "Families/Parents"
    },
    {
        fieldName: "target_group_other_impairments_ind",
        label: "Clients with other impairments (physical, mental)"
    },
    {
        fieldName: "target_group_CWIT_in_regulated_profession_ind",
        label: "Clients with international training in a regulated profession"
    },
    {
        fieldName: "target_group_CWIT_in_regulated_trade_ind",
        label: "Clients with international training in a regulated trade"
    },
    {
        fieldName: "target_group_official_language_minorities_ind",
        label: "Official Language minorities"
    },
]

export const CMCN_TARGET_GROUP_OPTIONS = [
    {
        fieldName: "target_group_children_ind",
        label: "Children (0-14 yrs)"
    },
    {
        fieldName: "target_group_youth_ind",
        label: "Youth (15-30 yrs)"
    },
    {
        fieldName: "target_group_senior_ind",
        label: "Seniors"
    },
    {
        fieldName: "target_group_gender_ind",
        label: "Gender-specific"
    },
    {
        fieldName: "target_group_refugee_ind",
        label: "Refugees"
    },
    {
        fieldName: "target_group_ethnic_ind",
        label: "Ethnic/cultural/linguistic group"
    },
    {
        fieldName: "target_group_hearing_ind",
        label: "Deaf or Hard of Hearing"
    },
    {
        fieldName: "target_group_visual_ind",
        label: "Blind or Partially Sighted"
    },
    {
        fieldName: "target_group_LGBTQ_ind",
        label: "Lesbian, Gay, Bisexual, Transgender, Queer (LGBTQ)"
    },
    {
        fieldName: "target_group_families_parents_ind",
        label: "Families/Parents"
    },
    {
        fieldName: "target_group_other_impairments_ind",
        label: "Other impairments (physical, mental)"
    },
    {
        fieldName: "target_group_CWIT_in_regulated_profession_ind",
        label: "Clients with international training in a regulated profession"
    },
    {
        fieldName: "target_group_CWIT_in_regulated_trade_ind",
        label: "Clients with international training in a regulated trade"
    },
    {
        fieldName: "target_group_official_language_minorities_ind",
        label: "Official Language minorities"
    },
]

export const SERVICE_DELIVERY_OPTIONS = [
    {
        fieldName: "service_delivery_format_in_person_ind",
        label: "In person"
    },
    {
        fieldName: "service_delivery_format_telephone_ind",
        label: "Telephone call/text/email"
    },
    {
        fieldName: "service_delivery_format_online_staff_ind",
        label: "Staff-led Online Service"
    },
    {
        fieldName: "service_delivery_format_online_self_ind",
        label: "Self-directed Online Service"
    },
    {
        fieldName: "service_delivery_format_correspondence_ind",
        label: "Self-directed via Correspondence"
    },
]

export const completeHours = {
    MIN: 0,
    MAX: 500,
};

export const completeMinutesEnum = Array.from({ length: 12 }, (_, index) => {
    const value = index * 5;
    return {
        label: `${value} minutes`,
        value
    };
});

export const languages = {
    ENGLISH: "english",
    FRENCH: "french"
}

export const ENGLISH_YES = '1:Yes'
export const ENGLISH_NO = '0:No'
export const FRENCH_YES = '1:Oui'
export const FRENCH_NO = '0:Non'
export const YES_ARRAY = ['1:Yes', '1:Oui']
export const NO_ARRAY = ['0:No', '0:Non']
export const SUPPORT_CHILDMINDING_FIELDNAME = "childminding_ind"
export const SUPPORT_TRANSLATION_FIELDNAME = "translation_ind"
export const SUPPORT_INTERPRETATION_FIELDNAME = "interpretation_ind"
export const ONE_OR_FAMILY_ORIENTATION = ["1:Orientation individuelle",
    "2:Orientation en famille", "1:One-on-one orientation",
    "2:Family orientation"]
export const GROUP_ORIENTATION = ["3:Group orientation", "3:Orientation en groupe"]

export const CMCN_STATUS_OF_SERVICE_ENDED_EARLY = ["2:Service ended early (i.e. client ended participation)", "2:A pris fin prématurément (c.-à-d. que le client a mis fin à sa participation)"]
export const CMCN_STATUS_OF_SERVICE_ONGOING = ["3:Ongoing", "3:En cours"]
export const CMCN_STATUS_OF_SERVICE_COMPLETED = ["1:Completed", "1:Achevé"]
export const CMCN_COMMUNITY_BASED_ACTIVITY = ["1:Community-based group events and activities", "1:Activités et événements de groupe communautaires"]
export const CMCN_TARGETED_MATCHING_ACTIVITY = ["2:Targeted Matching and Networking", "2:Jumelage et réseautage ciblés"]
export const CMCN_SERVICE_GROUP_SESSION = ["3:Targeted Matching and Networking: Group session (e.g. conversation circles)",
    "1:Community-based group events and activities: Group session (e.g. workshop, presentation)",
    "1:Activités et événements de groupe communautaires: Séance en groupe (p. ex. atelier, présentation)",
    "3:Jumelage et réseautage ciblés: Séance en groupe (p. ex. cercle de conversation)"]

export const ICARE_FORMS = {
    NARS: "nars",
    INFORMATION_AND_ORIENATATION: "info",
    CLIENT_PROFILE: "prof",
    COMMUNITY_CONNECTIONS: "cmcn",
    EMPLOYMENT: "employment"
}

export const DEFAULT_LIMIT = 10;
export const DEFAULT_PAGE = 1;

export const FORM_STATUS = {
    PENDING: "Pending",
    COMPLETED: "Completed"
}

export const CREATE_NEW_GROUP_KEY = "create_new_group"
export const NOT_EMP_NOT_APPLICABLE_ARRAY = ["5:Not employed/not applicable", "5:Sans emploi/Ne s'applique pas"]
export const INTERVENTION_TYPE_TEMPORARY_WORK_PLACEMENT_ARRAY = [
    "1:Temporary Work Placement",
    "1:Placement de travail temporaire"
];

export const INTERVENTION_TYPE_MENTORING_ARRAY = [
    "2:Mentoring",
    "2:Mentorat"
];

export const INTERVENTION_TYPE_WORKSHOP_ARRAY = [
    "7:Workshop",
    "7:Atelier"
];

export const INTERVENTION_TYPE_RESUME_SCREENING_AND_MATCHING_ARRAY = [
    "6:Resume Screening and Matching",
    "6:Préselection et jumelage de curriculum vitae"
];

export const INTERVENTION_TYPE_NETWORKING = [
    "5:Networking Opportunities",
    "5:Possibilité de réseautage"
]
export const INTERVENTION_TYPE_INDIVIDUAL = [
    "4:Individual Employment Counselling",
    "4:Counselling individuel en matière d'emploi"
]

export const INTERVENTION_STATUS_ENDED_EARLY_ARRAY = [
    "2:Intervention ended early (i.e. client ended participation)",
    "2:L'intervention a pris fin prématurément (Le client a mis fin à sa participation)"
]

export const INTERVENTION_TOPIC_ESSENTIAL_SKILL_ARRAY = [
    "7:Essential Skills (focused-training)",
    "7:Compétences essentielles (formation ciblée)"
]
